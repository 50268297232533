<template>
  <el-dialog title="兑换积分" :visible.sync="exchangeIntegralVisible" width="538px" :before-close="handleClose"
    :close-on-click-modal="false">
    <!--表单-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="rule-form">
      <el-form-item label="积分体系" prop="integralSystemId">
        <el-select v-model="ruleForm.integralSystemId" placeholder="请选择" class="w230" disabled>
          <el-option :label="item.systemName" :value="item.id" v-for="item in integralSystemList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="兑换积分数" prop="integralChange">
        <el-input-number v-model="ruleForm.integralChange" @change="handleChange" :min="1" class="w230">
        </el-input-number>
      </el-form-item>
      <el-form-item label="兑换时间" prop="beginTime">
        <el-date-picker
          class="w230"
          v-model="ruleForm.beginTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择兑换日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="兑换物" prop="exchangeItem">
        <el-input v-model="ruleForm.exchangeItem" class="w230" placeholder="请输入兑换的物品/服务"></el-input>
      </el-form-item>
      <el-form-item class="btns">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { getIntegralRecordList } from '@/api/integralSystem'
import { exchangeVolunteerIntegral } from '@/api/integralRecord'
export default {
  name: '',
  components: {},
  props: {
    exchangeIntegralVisible: {
      type: Boolean,
      default: false
    },
    volunteerId: Number,
    systemId: Number,
    integral: Number
  },
  data() {
    // 1 新增 2 减少 3 转让 4 兑换
    return {
      integralSystemList: [],
      ruleForm: {
        beginTime: '',
        exchangeItem: '',
        integralChange: '',
        integralOperation: 4,
        integralSystemId: this.systemId,
        volunteerId: this.volunteerId
      },
      rules: {
        integralChange: [
          { required: true, message: '请输入兑换积分数', trigger: 'blur' }
        ],
        integralSystemId: [
          { required: true, message: '请选择积分体系', trigger: 'onchange' }
        ],
        beginTime: [
          { required: true, message: '请选择兑换时间', trigger: 'onchange' }
        ],
        exchangeItem: [
          { required: true, message: '请输入兑换的物品/服务', trigger: 'onchange' }
        ],
      }
    }
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.getIntegralRecordList()
  },
  methods: {
    async getIntegralRecordList() {
      const res = await getIntegralRecordList()
      this.integralSystemList = res.data
    },
    submitForm(formName) {
      if (this.integral<this.ruleForm.integralChange) {
        this.$message.error('积分不够！')
        return false
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
           const res = await exchangeVolunteerIntegral(this.ruleForm)
           this.$message.success(res.data)
           this.$emit('updateData')
           this.handleClose()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChange() {},
    handleClose() {
      // this.$refs['ruleForm'].resetFields()
      this.$emit('update:exchangeIntegralVisible', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.w230 {
  width: 230px;
}
</style>