<template>
  <el-dialog title="转让积分" :visible.sync="transferIntegralVisible" width="538px" :before-close="handleClose"
    :close-on-click-modal="false">
    <!--表单-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="rule-form">
      <el-form-item label="积分体系" prop="integralSystemId">
        <el-select v-model="ruleForm.integralSystemId" placeholder="请选择" class="w230" disabled>
          <el-option :label="item.systemName" :value="item.id" v-for="item in integralSystemList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="转让积分数" prop="integralChange">
        <el-input-number v-model="ruleForm.integralChange" @change="handleChange" :min="1" class="w230">
        </el-input-number>
      </el-form-item>
      <el-form-item label="转让人" prop="volunteerName">
        <el-input v-model="ruleForm.volunteerName" class="w230" placeholder="请输入" disabled></el-input>
      </el-form-item>
      <el-form-item label="转让人所属团体" prop="toGroupId">
        <el-select v-model="ruleForm.toGroupId" placeholder="请选择" class="w230" @change="groupChange">
          <el-option :label="item.groupName" :value="item.id" v-for="item in groupList" :key="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="转让对象" prop="toVolunteerId">
        <el-select v-model="ruleForm.toVolunteerId" placeholder="请选择" class="w230"
          @change="toVolunteerChange">
          <el-option :label="item.volunteerName" :value="item.id" v-for="item in volunteerList" :key="item.id" :disabled="item.id===info.volunteerId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="转让对象身份证号" prop="toIdNumber">
        <el-input v-model="ruleForm.toIdNumber" class="w230" placeholder="请输入" disabled></el-input>
      </el-form-item>
      <el-form-item label="转让原因" prop="description">
        <el-input type="textarea" v-model="ruleForm.description" placeholder="请输入" class="w230">
        </el-input>
      </el-form-item>
      <el-form-item class="btns">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { getIntegralRecordList } from '@/api/integralSystem'
import {
  transferVolunteerIntegral,
  getVolunteerByGroupId,
  getVolunteerGroupByGovernmentId
} from '@/api/integralRecord'
export default {
  name: '',
  components: {},
  props: {
    transferIntegralVisible: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: {}
    }
  },
  data() {
    // 1 新增 2 减少 3 转让 4 兑换
    return {
      integralSystemList: [],
      ruleForm: {
        description: '',
        integralChange: '',
        integralOperation: 3,
        integralSystemId: this.info.systemId,
        toVolunteerName: '',
        toVolunteerId: '',
        toIdNumber: '',
        toGroupId: '',
        volunteerName: this.info.name,
        volunteerId: this.info.volunteerId
      },
      rules: {
        volunteerName: [{ required: true }],
        description: [
          { required: true, message: '请输入志愿服务原因', trigger: 'blur' }
        ],
        toIdNumber: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
          {
            pattern:
              /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i,
            message: '请输入正确的身份证号',
            trigger: 'blur'
          }
        ],
        integralChange: [
          { required: true, message: '请输入新增积分数', trigger: 'blur' }
        ],
        integralSystemId: [
          { required: true, message: '请选择积分体系', trigger: 'onchange' }
        ],
        toVolunteerId: [
          { required: true, message: '请选择', trigger: 'onchange' }
        ],
        toGroupId: [{ required: true, message: '请选择', trigger: 'onchange' }]
      },
      volunteerList: [],
      groupList: []
    }
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.getIntegralRecordList()
    this.getVolunteerGroupByGovernmentId()
  },
  methods: {
    async getVolunteerByGroupId(val) {
      const res = await getVolunteerByGroupId({ groupId: Number(val) })
      this.volunteerList = res.data
    },
    async getVolunteerGroupByGovernmentId() {
      const res = await getVolunteerGroupByGovernmentId({
        volunteerId: this.info.volunteerId
      })
      this.groupList = res.data
    },
    async getIntegralRecordList() {
      const res = await getIntegralRecordList()
      this.integralSystemList = res.data
    },
    groupChange(val) {
      console.log(val)
      this.getVolunteerByGroupId(val)
    },
    toVolunteerChange(id) {
      this.ruleForm.toIdNumber = this.volunteerList.find(item=>id===item.id).idNumber
      this.ruleForm.toVolunteerName = this.volunteerList.find(item=>id===item.id).volunteerName
    },
    submitForm(formName) {
      if (this.info.integral < this.ruleForm.integralChange) {
        this.$message.error('积分不够！')
        return false
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await transferVolunteerIntegral(this.ruleForm)
          this.$message.success(res.data)
          this.$emit('updateData')
          this.handleClose()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleChange() {},
    handleClose() {
      // this.$refs['ruleForm'].resetFields()
      this.$emit('update:transferIntegralVisible', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.w230 {
  width: 230px;
}
</style>